<template>
  <div class="relative h-full w-full bg-color-primary flex flex-col">
    <div class="w-full shadow-md z-50 sticky top-0 inset-x-0 bg-white">
      <Navbar />
    </div>
    <div class="w-full duration-200 bg-color-primary">
      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>
    </div>
    <div
      v-if="
        currentRouteName !== 'Register' &&
          currentRouteName !== 'ForgotPassword' &&
          currentRouteName !== 'ResetPassword' &&
          currentRouteName !== 'DownloadApp'
      "
      class="w-full border-t-2 border-[#EEEEEE] mt-0 md:mt-8 bg-white"
      :class="
        currentRouteName === 'Home' ||
        currentRouteName == 'MyClasses' ||
        currentRouteName == 'Profile'
          ? 'mb-14 md:mb-0'
          : currentRouteName === 'Cart'
          ? 'mb-40 md:mb-0'
          : currentRouteName == 'Payment'
          ? 'mb-20 md:mb-0'
          : ''
      "
    >
      <Footer />
    </div>
    <div
      v-if="
        currentRouteName === 'Home' ||
          currentRouteName === 'MyClasses' ||
          currentRouteName === 'Profile' ||
          currentRouteName === 'Notification' ||
          currentRouteName === 'Category' ||
          currentRouteName === 'Search'
      "
      class="w-full z-100 z-40"
    >
      <MobileNavbar />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Navbar from './Navbar.vue'
import MobileNavbar from './MobileNavbar.vue'
import Footer from './Footer.vue'
import Auth from '@/utils/Auth'

export default defineComponent({
  name: 'Layout',
  setup() {
    return {
      Auth,
    }
  },
  components: {
    Navbar,
    MobileNavbar,
    Footer,
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
})
</script>
<style scoped>
/* * SCROLLBAR */
#scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
#scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}
#scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #9e9e9e;
}
.route-enter-from,
.route-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.5s ease;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
</style>
