<template>
  <div>
    <transition name="fade" appear>
      <div
        v-if="showBannerDownload && currentRouteName !== 'DownloadApp'"
        class="w-full bg-[#F5F7FD] px-4 py-2 flex md:hidden items-center justify-between space-x-2"
      >
        <div class="flex flex-row space-x-2 items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 cursor-pointer flex-shrink-0"
            @click="showBannerDownload = false"
          >
            <path
              d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
              fill="#212121"
            />
          </svg>
          <div class="flex flex-row space-x-2 items-center">
            <img
              src="/assets/icons/icon.webp"
              class="w-12 h-12 rounded-md shadow"
              alt=""
            />
            <div class="flex flex-col justify-center">
              <p class="font-solusi text-xs font-medium">
                Akses Sekolah Desain lebih mudah dengan aplikasi, download
                sekarang!
              </p>
            </div>
          </div>
        </div>
        <router-link
          to="/download"
          class="p-2 text-sm font-solusi font-semibold rounded-md text-white bg-[#00446F] flex-shrink-0"
        >
          Buka App
        </router-link>
      </div>
    </transition>
    <transition name="navbar" mode="out-in">
      <div
        v-if="openSearch"
        class="max-w-6xl container flex justify-between py-2 px-4 m-auto h-14"
      >
        <div class="flex-grow w-full items-center flex">
          <div @click="openSearch = false" class="cursor-pointer pr-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="w-6 h-6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.07942 12.3116C2.08342 12.3226 2.08342 12.3336 2.08842 12.3446C2.08842 12.3466 2.08842 12.3476 2.08942 12.3496C2.14942 12.4916 2.23742 12.6116 2.33942 12.7196C2.35042 12.7316 2.35442 12.7466 2.36542 12.7586L10.0134 20.3366C10.2624 20.5826 10.5884 20.7056 10.9144 20.7056C11.2404 20.7056 11.5654 20.5826 11.8144 20.3366C12.3124 19.8436 12.3124 19.0456 11.8144 18.5526L6.34042 13.1276L21.1054 13.1276C21.8094 13.1276 22.3784 12.5636 22.3784 11.8656C22.3784 11.1696 21.8094 10.6046 21.1054 10.6046L6.34142 10.6046L11.8154 5.15257C12.3134 4.66057 12.3134 3.86157 11.8154 3.36957C11.3184 2.87657 10.5114 2.87657 10.0134 3.36957L2.36542 10.9736C2.25542 11.0826 2.17642 11.2116 2.11442 11.3476C2.09442 11.3896 2.08342 11.4306 2.06942 11.4726C2.03542 11.5746 2.01442 11.6766 2.00742 11.7846C2.00442 11.8286 1.99842 11.8696 2.00042 11.9136C2.00542 12.0496 2.02942 12.1816 2.07942 12.3116Z"
                fill="#212121"
              />
            </svg>
          </div>
          <div
            class="h-12 rounded-lg justify-between items-center relative flex-grow flex"
          >
            <input
              type="text"
              name="search"
              id="search"
              v-model="search"
              v-on:keyup.enter="searching()"
              placeholder="Cari disini..."
              class="w-full outline-none active:outline-none font-solusi text-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 pl-2 pr-8 py-1.5 rounded duration-300 shadow bg-white"
            />
            <button
              @click="searching"
              class="absolute top-2.5 right-1 outline-none focus:outline-none active:outline-none"
            >
              <svg
                class="w-7 h-7"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.32812 12.7031C7.20312 13.5781 8.26562 14.0156 9.51562 14.0156C10.7656 14.0156 11.8281 13.5781 12.7031 12.7031C13.5781 11.8281 14.0156 10.7656 14.0156 9.51562C14.0156 8.26562 13.5781 7.20312 12.7031 6.32812C11.8281 5.45312 10.7656 5.01562 9.51562 5.01562C8.26562 5.01562 7.20312 5.45312 6.32812 6.32812C5.45312 7.20312 5.01562 8.26562 5.01562 9.51562C5.01562 10.7656 5.45312 11.8281 6.32812 12.7031ZM15.5156 14.0156L20.4844 18.9844L18.9844 20.4844L14.0156 15.5156V14.7188L13.7344 14.4375C12.5469 15.4688 11.1406 15.9844 9.51562 15.9844C7.70312 15.9844 6.15625 15.3594 4.875 14.1094C3.625 12.8594 3 11.3281 3 9.51562C3 7.70312 3.625 6.17188 4.875 4.92188C6.15625 3.64062 7.70312 3 9.51562 3C11.3281 3 12.8594 3.64062 14.1094 4.92188C15.3594 6.17188 15.9844 7.70312 15.9844 9.51562C15.9844 11.1406 15.4688 12.5469 14.4375 13.7344L14.7188 14.0156H15.5156Z"
                  fill="#212121"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="max-w-6xl container flex justify-between py-2 px-4 m-auto h-14 items-center"
      >
        <div class="flex-grow flex items-center justify-between">
          <router-link to="/">
            <img
              src="/assets/images/mentor/logo-primary.svg"
              alt=""
              class="h-7 pr-3"
            />
          </router-link>
          <div class="hidden md:flex">
            <div class="px-3 self-center">
              <router-link to="/category" v-slot="{ isExactActive }">
                <span
                  class="hover:text-blue-500 font-solusi text-sm flex items-center"
                  :class="
                    isExactActive
                      ? 'text-[#009DFF] font-semibold'
                      : 'text-[#212121] font-medium'
                  "
                >
                  Kategori
                </span>
              </router-link>
            </div>

            <div v-if="Auth.isLoggedIn()" class="px-3 self-center">
              <router-link to="/my-classes?page=transaction">
                <span
                  class="hover:text-blue-500 font-solusi text-sm"
                  :class="
                    currentRouteValue.fullPath == '/my-classes?page=transaction'
                      ? 'text-[#009DFF] font-semibold'
                      : 'text-[#212121] font-medium'
                  "
                >
                  Pembelian
                </span>
              </router-link>
            </div>

            <div v-if="Auth.isLoggedIn()" class="px-3 self-center">
              <router-link to="/my-classes">
                <span
                  class="hover:text-blue-500 font-solusi text-sm"
                  :class="
                    currentRouteValue.fullPath == '/my-classes'
                      ? 'text-[#009DFF] font-semibold'
                      : 'text-[#212121] font-medium'
                  "
                >
                  Kelas Saya
                </span>
              </router-link>
            </div>
          </div>
          <div
            class="h-10 ml-3 rounded-lg justify-between items-center relative flex-grow hidden md:flex"
          >
            <input
              type="text"
              name="search"
              id="search"
              v-model="search"
              v-on:keyup.enter="searching"
              placeholder="Cari disini..."
              class="w-full outline-none active:outline-none font-solusi text-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 pl-2 pr-8 py-1.5 rounded duration-300 shadow-sm bg-white"
            />
            <button
              @click="searching()"
              type="submit"
              class="absolute top-2 right-1 outline-none focus:outline-none active:outline-none"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.32812 12.7031C7.20312 13.5781 8.26562 14.0156 9.51562 14.0156C10.7656 14.0156 11.8281 13.5781 12.7031 12.7031C13.5781 11.8281 14.0156 10.7656 14.0156 9.51562C14.0156 8.26562 13.5781 7.20312 12.7031 6.32812C11.8281 5.45312 10.7656 5.01562 9.51562 5.01562C8.26562 5.01562 7.20312 5.45312 6.32812 6.32812C5.45312 7.20312 5.01562 8.26562 5.01562 9.51562C5.01562 10.7656 5.45312 11.8281 6.32812 12.7031ZM15.5156 14.0156L20.4844 18.9844L18.9844 20.4844L14.0156 15.5156V14.7188L13.7344 14.4375C12.5469 15.4688 11.1406 15.9844 9.51562 15.9844C7.70312 15.9844 6.15625 15.3594 4.875 14.1094C3.625 12.8594 3 11.3281 3 9.51562C3 7.70312 3.625 6.17188 4.875 4.92188C6.15625 3.64062 7.70312 3 9.51562 3C11.3281 3 12.8594 3.64062 14.1094 4.92188C15.3594 6.17188 15.9844 7.70312 15.9844 9.51562C15.9844 11.1406 15.4688 12.5469 14.4375 13.7344L14.7188 14.0156H15.5156Z"
                  fill="#212121"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="relative flex items-center ml-2">
          <div
            class="block md:hidden cursor-pointer"
            @click="openSearch = true"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="h-7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.32812 12.7031C7.20312 13.5781 8.26562 14.0156 9.51562 14.0156C10.7656 14.0156 11.8281 13.5781 12.7031 12.7031C13.5781 11.8281 14.0156 10.7656 14.0156 9.51562C14.0156 8.26562 13.5781 7.20312 12.7031 6.32812C11.8281 5.45312 10.7656 5.01562 9.51562 5.01562C8.26562 5.01562 7.20312 5.45312 6.32812 6.32812C5.45312 7.20312 5.01562 8.26562 5.01562 9.51562C5.01562 10.7656 5.45312 11.8281 6.32812 12.7031ZM15.5156 14.0156L20.4844 18.9844L18.9844 20.4844L14.0156 15.5156V14.7188L13.7344 14.4375C12.5469 15.4688 11.1406 15.9844 9.51562 15.9844C7.70312 15.9844 6.15625 15.3594 4.875 14.1094C3.625 12.8594 3 11.3281 3 9.51562C3 7.70312 3.625 6.17188 4.875 4.92188C6.15625 3.64062 7.70312 3 9.51562 3C11.3281 3 12.8594 3.64062 14.1094 4.92188C15.3594 6.17188 15.9844 7.70312 15.9844 9.51562C15.9844 11.1406 15.4688 12.5469 14.4375 13.7344L14.7188 14.0156H15.5156Z"
                fill="#212121"
              />
            </svg>
          </div>
          <div v-if="Auth.isLoggedIn()" class="px-2 relative">
            <button
              @mouseover="
                isMobile ? null : ((openCart = true), (openNotif = false))
              "
              @click=";(openCart = !openCart), (openNotif = false)"
              class="relative outline-none focus:outline-none flex items-center z-20"
            >
              <span
                v-if="getCartData.length"
                class="bg-[#DE1306] font-solusi absolute -top-1 -right-2 border-2 border-white rounded-full text-white nav-badge flex items-center justify-center"
                >{{ getCartData.length }}</span
              >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="h-7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8951 5.68783C22.4629 5.13564 21.7907 4.80002 21.0951 4.80002H6.47956L6.02395 3.02441C5.75956 1.99222 4.84833 1.27222 3.79175 1.27222H1.34399C0.936185 1.27222 0.599609 1.60783 0.599609 2.0166C0.599609 2.42441 0.935225 2.76098 1.34399 2.76098H3.79175C4.15175 2.76098 4.46395 3.00098 4.55956 3.36098L7.48732 15.0255C7.75171 16.0577 8.66294 16.7777 9.71951 16.7777H19.295C20.3507 16.7777 21.2872 16.0577 21.5272 15.0255L23.3272 7.63322C23.495 6.9601 23.3516 6.2401 22.895 5.6879L22.8951 5.68783ZM21.8638 7.29564L20.0638 14.6879C19.9682 15.0479 19.656 15.2879 19.296 15.2879H9.71954C9.35954 15.2879 9.04735 15.0479 8.95173 14.6879L6.86392 6.31236H21.0962C21.3362 6.31236 21.5762 6.43236 21.7205 6.62455C21.864 6.8158 21.9352 7.05581 21.864 7.29581L21.8638 7.29564Z"
                  fill="#212121"
                />
                <path
                  d="M10.224 17.7356C8.85623 17.7356 7.72852 18.8634 7.72852 20.2311C7.72852 21.5988 8.85632 22.7266 10.224 22.7266C11.5918 22.7276 12.7196 21.5998 12.7196 20.2318C12.7196 18.8639 11.5917 17.7354 10.224 17.7354V17.7356ZM10.224 21.2156C9.67184 21.2156 9.23965 20.7834 9.23965 20.2312C9.23965 19.679 9.67184 19.2468 10.224 19.2468C10.7762 19.2468 11.2084 19.679 11.2084 20.2312C11.2075 20.76 10.7519 21.2156 10.224 21.2156Z"
                  fill="#212121"
                />
                <path
                  d="M18.4799 17.7356C17.1121 17.7356 15.9844 18.8634 15.9844 20.2311C15.9844 21.5988 17.1122 22.7266 18.4799 22.7266C19.8476 22.7266 20.9754 21.5988 20.9754 20.2311C20.952 18.8642 19.8476 17.7356 18.4799 17.7356ZM18.4799 21.2156C17.9277 21.2156 17.4955 20.7834 17.4955 20.2312C17.4955 19.679 17.9277 19.2468 18.4799 19.2468C19.0321 19.2468 19.4643 19.679 19.4643 20.2312C19.4643 20.76 19.0077 21.2156 18.4799 21.2156Z"
                  fill="#212121"
                />
              </svg>
            </button>
            <div
              v-if="openCart && !isMobile"
              @click="openCart = false"
              class="fixed inset-0 h-full w-full z-10"
            ></div>
            <transition name="icon">
              <div
                @mouseleave="isMobile ? null : (openCart = false)"
                v-if="openCart"
                class="transition-all transform absolute right-0 top-8 mt-0 w-80 origin-top-right bg-white border border-gray-200 divide-y divide-gray-200 rounded-md shadow-md outline-none z-20"
              >
                <p
                  class="text-sm leading-5 font-solusi font-semibold px-2.5 py-2"
                >
                  Keranjang
                </p>
                <div
                  v-if="getCartData.length > 0"
                  class="divide-y divide-gray-200"
                >
                  <div
                    v-for="(cart, i) in getCartData"
                    :key="i"
                    class="px-2.5 py-2 hover:bg-gray-100 duration-300 cursor-pointer"
                    @click.prevent="redirectToCart"
                  >
                    <div class="grid grid-cols-3 gap-2">
                      <div class="col-span-2 flex items-center">
                        <img
                          :src="cart.thumbnail"
                          alt=""
                          class="object-cover rounded-md h-9 w-9"
                        />
                        <div class="ml-1.5 flex-grow truncate">
                          <p
                            class="font-solusi text-xs text-[#383838] truncate font-semibold"
                          >
                            {{ cart.name }}
                          </p>
                          <p
                            v-if="cart.mentor_data"
                            class="font-solusi text-2xs text-[#757575] font-medium"
                          >
                            Oleh {{ cart.mentor_data[0].name }}
                          </p>
                        </div>
                      </div>
                      <div class="col-span-1">
                        <p
                          class="font-solusi text-xs text-[#383838] text-right font-semibold"
                        >
                          Rp. {{ cart.price_formatted }}
                        </p>
                        <p
                          class="font-solusi text-2xs text-[#DE1306] text-right font-medium line-through"
                        >
                          Rp. {{ cart.normal_price_formatted }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="divide-y divide-gray-200">
                  <p
                    class="text-center py-3 font-solusi font-semibold text-xs text-[#757575]"
                  >
                    Keranjang anda kosong
                  </p>
                </div>
                <div v-if="getCartData.length > 0" class="p-2">
                  <div
                    @click="redirectToCart"
                    class="text-xs text-blue-600 hover:text-[#274977]0 font-solusi font-semibold flex justify-center cursor-pointer"
                  >
                    Lihat semua
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div v-if="Auth.isLoggedIn()" class="px-2 relative">
            <button
              @mouseover="
                isMobile ? null : ((openNotif = true), (openCart = false))
              "
              @click=";(openNotif = !openNotif), (openCart = false)"
              class="relative outline-none focus:outline-none flex items-center z-20"
            >
              <span
                v-if="getUnReadNotification.length > 0"
                class="bg-[#DE1306] font-solusi absolute -top-1 -right-1 border-2 border-white rounded-full text-white nav-badge flex items-center justify-center"
                >{{ getUnReadNotification.length }}</span
              >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="h-7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.4571 19.2307C15.3565 21.0531 13.848 22.4999 12.0001 22.4999C10.1521 22.4999 8.64369 21.0531 8.54304 19.2307H3.15535C2.51681 19.2307 2 18.7154 2 18.0784V16.923C2 15.649 3.03365 14.6153 4.30919 14.6153C4.30769 14.6153 4.30769 9.99993 4.30769 9.99993C4.30769 6.74874 6.34046 3.89878 9.30769 2.79147V2.69081C9.30769 1.20492 10.5141 0 12 0C13.4874 0 14.6923 1.20492 14.6923 2.69081V2.79147C17.6595 3.89874 19.6923 6.74878 19.6923 9.99993V14.6153C20.9633 14.6153 22 15.649 22 16.923V18.0784C22 18.7124 21.4817 19.2307 20.8447 19.2307H15.4571ZM13.9142 19.2307H10.086C10.1837 20.2027 11.0025 20.9615 12.0001 20.9615C12.9977 20.9615 13.8165 20.2027 13.9142 19.2307H13.9142ZM20.4615 17.6922V16.923C20.4615 16.4993 20.1144 16.1538 19.6907 16.1538C18.8419 16.1538 18.1538 15.4672 18.1538 14.6153V9.99993C18.1538 7.24001 16.3208 4.83916 13.7095 4.08647L13.1537 3.92571V2.69075C13.1537 2.05375 12.6368 1.5384 11.9998 1.5384C11.3643 1.5384 10.846 2.05525 10.846 2.69075V3.92571L10.2901 4.08647C7.67888 4.83916 5.84585 7.23851 5.84585 9.99993V14.6153C5.84585 15.4642 5.15623 16.1538 4.30888 16.1538C3.88369 16.1538 3.53815 16.4993 3.53815 16.923V17.6922H20.4615Z"
                  fill="#212121"
                />
              </svg>
            </button>
            <div
              v-if="openNotif && !isMobile"
              @click="openNotif = false"
              class="fixed inset-0 h-full w-full z-10"
            ></div>
            <transition name="icon">
              <div
                @mouseleave="isMobile ? null : (openNotif = false)"
                v-if="openNotif"
                class="transition-all transform absolute right-0 top-8 w-72 mt-0 origin-top-right bg-white border border-gray-200 divide-y divide-gray-200 rounded-md shadow-md outline-none z-20"
              >
                <div class="px-2.5 py-2 flex items-end justify-between">
                  <p class="text-sm leading-5 font-solusi font-semibold">
                    Notifikasi
                  </p>
                  <!-- <a class="font-solusi text-tritary text-xs"
                    >Tandai semuanya</a
                  > -->
                </div>
                <div
                  v-if="getUnReadNotification.length > 0"
                  class="divide-y divide-gray-200 max-h-[40vh] overflow-auto scroll-hide"
                >
                  <router-link
                    v-for="(notification, i) in getUnReadNotification"
                    :key="i"
                    to="/notification"
                    class="px-2.5 py-2 hover:bg-gray-100 duration-300 flex flex-col"
                    :class="
                      notification.is_read ? 'bg-[#FFFFFF]' : 'bg-[#FFFAF1]'
                    "
                  >
                    <div class="flex items-center justify-between">
                      <span
                        class="font-solusi text-2xs text-[#757575] font-medium flex items-center"
                      >
                        {{ dateNotif(notification.created_at) }}
                        <div
                          class="w-1 h-1 bg-[#757575] rounded-full mx-1"
                        ></div>
                        {{ timeSince(notification.created_at) }}
                      </span>
                      <svg
                        v-if="!notification.is_read"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4" cy="4" r="4" fill="#0A72ED" />
                      </svg>
                    </div>
                    <p
                      class="font-solusi text-xs font-semibold line-clamp-2 overflow-hidden text-[#333333]"
                    >
                      {{ notification.content }}
                    </p>
                  </router-link>
                  <router-link
                    v-for="(notification, i) in getNotificationData"
                    :key="i"
                    to="/notification"
                    class="px-2.5 py-2 hover:bg-gray-100 duration-300 flex flex-col"
                    :class="
                      notification.is_read ? 'bg-[#FFFFFF]' : 'bg-[#FFFAF1]'
                    "
                  >
                    <div class="flex items-center justify-between">
                      <span
                        class="font-solusi text-2xs text-[#757575] font-medium flex items-center"
                      >
                        {{ dateNotif(notification.created_at) }}
                        <div
                          class="w-1 h-1 bg-[#757575] rounded-full mx-1"
                        ></div>
                        {{ timeSince(notification.created_at) }}
                      </span>
                      <svg
                        v-if="!notification.is_read"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4" cy="4" r="4" fill="#0A72ED" />
                      </svg>
                    </div>
                    <p
                      class="font-solusi text-xs font-semibold line-clamp-2 overflow-hidden text-[#333333]"
                    >
                      {{ notification.content }}
                    </p>
                  </router-link>
                </div>
                <div v-else class="divide-y divide-gray-200">
                  <div
                    class="px-2.5 py-2 bg-[#FFFFFF] hover:bg-gray-100 duration-300 flex flex-col"
                  >
                    <p
                      class="font-solusi text-xs font-semibold line-clamp-2 overflow-hidden text-[#757575] text-center"
                    >
                      Tidak ada notifikasi
                    </p>
                  </div>
                </div>
                <div
                  v-if="
                    getNotificationData.length > 0 ||
                      getUnReadNotification.length > 0
                  "
                  class="p-2"
                >
                  <router-link
                    to="/notification"
                    class="text-xs text-blue-600 hover:text-[#274977]0 font-solusi font-semibold flex justify-center"
                  >
                    Lihat semua
                  </router-link>
                </div>
              </div>
            </transition>
          </div>

          <button
            v-if="Auth.isLoggedIn()"
            @click="showProfile = !showProfile"
            class="hidden md:inline-flex justify-center items-center max-w-15 ml-2 py-0.5 pl-1 pr-3 font-medium leading-5 transition duration-150 ease-in-out text-white bg-[#00446F] rounded-full focus:outline-none focus:shadow-outline-blue h-10 md:h-9"
            type="button"
          >
            <img
              v-if="
                getProfileData.photo &&
                  getProfileData.photo !=
                    'https://platform.sekolahdesain.id/assets/images/navbar/profile2.svg'
              "
              :src="getProfileData.photo"
              class="rounded-full md:mr-2 self-center h-8 md:h-7 w-8 md:w-7"
              alt=""
            />
            <div
              v-else
              class="w-8 h-8 md:w-7 md:h-7 rounded-full text-center font-solusi capitalize align-middle text-white inline-flex justify-center items-center text-sm md:mr-1 font-semibold"
              :style="'background-color: ' + toHex(getProfileData?.name)"
            >
              {{ getInitialName(getProfileData?.name) }}
            </div>
            <span
              class="block font-solusi font-semibold text-sm truncate max-w-[100px]"
              >{{ getProfileData.name }}</span
            >
            <svg
              class="w-5 h-5 ml-2 -mr-1"
              :class="
                showProfile
                  ? 'duration-500 transform -rotate-180'
                  : 'duration-500 transform rotate-0'
              "
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <router-link
            v-else
            class="hidden md:inline-flex justify-center items-center w-full ml-3 py-0.5 pl-1 pr-3 font-medium leading-5 transition duration-150 ease-in-out text-white bg-[#00446F] rounded-full focus:outline-none focus:shadow-outline-blue h-10 md:h-9"
            to="/register"
          >
            <img
              src="/assets/images/navbar/profile.svg"
              class="rounded-full md:mr-2 self-center h-8 md:h-7"
              alt=""
            />
            <span class="block font-solusi font-semibold text-sm">Masuk</span>
          </router-link>
          <div
            v-if="showProfile"
            @click="showProfile = false"
            class="fixed inset-0 h-full w-full z-10"
          ></div>
          <transition name="profile">
            <div
              v-if="showProfile"
              class="transition-all absolute right-0 top-8 w-40 mt-3 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-md outline-none z-20"
            >
              <div v-if="Auth.isLoggedIn()">
                <div class="p-3">
                  <p class="mb-2.5">
                    <button
                      @click="openProfile"
                      class="text-[#212121] text-base leading-5 cursor-pointer font-solusi flex items-end"
                    >
                      <svg
                        class="w-5 h-5 mr-1"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00003 2.5C6.4231 2.5 5.13585 3.75833 5.13585 5.3C5.13585 6.84167 6.42303 8.1 8.00003 8.1C9.57704 8.1 10.8642 6.84167 10.8642 5.3C10.8642 3.75833 9.57704 2.5 8.00003 2.5ZM8.00003 3.3C9.13483 3.3 10.0459 4.19063 10.0459 5.3C10.0459 6.40937 9.13483 7.3 8.00003 7.3C6.86523 7.3 5.95419 6.40937 5.95419 5.3C5.95419 4.19063 6.86523 3.3 8.00003 3.3ZM8.00003 8.9C4.91736 8.9 2.36701 11.1464 2.00312 14.05C1.98874 14.1635 2.02444 14.2781 2.10169 14.3641C2.17947 14.45 2.29082 14.4995 2.40803 14.5H13.592C13.7092 14.4995 13.8205 14.45 13.8983 14.3641C13.9756 14.2781 14.0113 14.1635 13.9969 14.05C13.633 11.1464 11.0826 8.9 7.99997 8.9H8.00003ZM8.00003 9.7C10.5121 9.7 12.5781 11.4183 13.0848 13.7H2.9153C3.42196 11.4183 5.48801 9.7 8.00003 9.7Z"
                          fill="currentColor"
                        />
                      </svg>
                      Profil
                    </button>
                  </p>
                  <p>
                    <button
                      @click="openLogout"
                      class="text-[#DE1306] text-base leading-5 cursor-pointer font-solusi flex items-center"
                    >
                      <svg
                        class="w-5 h-5 mr-1"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.94082 4.46808C6.08207 4.48683 6.11895 4.51058 6.18456 4.55683C6.47394 4.76058 6.43706 5.26746 6.08831 5.43058C6.07581 5.4362 6.06331 5.44183 6.05144 5.44808C4.80518 6.04808 3.86328 7.2468 3.58456 8.6044C3.1683 10.63 4.31269 12.875 6.29576 13.6857C7.9252 14.3519 9.94456 13.9682 11.222 12.6594C12.5232 11.3269 12.8695 9.15942 12.0208 7.47942C11.5664 6.58067 10.7976 5.84374 9.88891 5.4207C9.88891 5.4207 9.59954 5.24195 9.59954 4.96696C9.59891 4.62446 9.99328 4.36196 10.3558 4.53321C11.982 5.30321 13.1771 6.90441 13.4452 8.69257C13.7121 10.4713 13.0515 12.3601 11.7016 13.5876C10.1091 15.0345 7.63787 15.4227 5.67147 14.5039C3.48139 13.4801 2.11835 10.9301 2.58891 8.47379C2.91203 6.78755 4.07517 5.28259 5.61835 4.54323C5.70273 4.50323 5.81773 4.46198 5.94085 4.46698L5.94082 4.46808ZM8.02962 2.00184C8.12587 2.01059 8.1415 2.01997 8.18275 2.03622C8.34838 2.10184 8.47274 2.25996 8.49524 2.43871C8.49899 2.46684 8.49837 2.47371 8.49899 2.50184V7.50872C8.49524 7.62497 8.48024 7.65934 8.45087 7.72246C8.30524 8.03184 7.80712 8.09809 7.59274 7.80371C7.53149 7.71871 7.50024 7.61496 7.49711 7.50934V2.50246C7.50024 2.40559 7.50774 2.38934 7.52149 2.34746C7.57961 2.16809 7.74648 2.02933 7.93523 2.00559C7.99523 1.99809 8.00961 2.00184 8.02898 2.00247L8.02962 2.00184Z"
                          fill="currentColor"
                        />
                      </svg>
                      Keluar
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <a
          v-if="Auth.isLoggedIn() && getProfileData.role_id == 2"
          class="inline-flex text-sm rounded-md px-2 py-1.5 font-semibold md:ml-2 border border-[#088BD5] text-[#088BD5] hover:bg-[#088BD5] hover:text-white shadow hover:shadow-md duration-300"
          href="/mentor"
        >
          Mentor Area
        </a>
      </div>
    </transition>

    <ModalLogout
      :show="showModalLogout"
      :close="closeLogout"
      :submit="logout"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'
import ModalLogout from '@/main/components/Modals/ModalLogout.vue'
import moment from 'moment'
import NotificationModel from '@/models/NotificationModel'

export default defineComponent({
  name: 'Navbar Member',

  components: {
    ModalLogout,
  },

  setup() {
    return {
      Auth,
    }
  },

  data() {
    return {
      showProfile: false,
      openSearch: false as boolean,
      openCart: false,
      openNotif: false,
      routes: [
        {
          name: 'Beranda',
          path: '/',
        },
        {
          name: 'Kategori',
          path: '/category',
        },
        {
          name: 'Kelas Saya',
          path: '/my-classes',
        },
      ],
      isMobile: false,
      showModalLogout: false,
      search: '' as any,
      notifications: [] as any,
      showBannerDownload: true,
    }
  },

  created() {
    if (window.innerWidth < 768) {
      this.isMobile = true
    }
    if (this.currentRouteName == 'Search') {
      const query: any = this.$route.query.q
      this.search = decodeURI(query)
    }
  },

  async mounted() {
    // console.log(this.$router.currentRoute.value)
    if (Auth.isLoggedIn()) {
      if (Object.keys(this.getProfileData).length == 0) {
        this.getProfile()
      }
      if (Object.keys(this.getCartData).length == 0) {
        this.getCart()
      }
      await this.getNotification()
    }
  },

  computed: {
    currentRouteValue() {
      return this.$router.currentRoute.value
    },
    currentRouteName() {
      return this.$route.name
    },
    getProfileData(): any {
      return this.$store.getters['profileStore/getProfileData']
    },
    getCartLoading(): boolean {
      return this.$store.getters['cartStore/getCartLoading']
    },
    getCartData(): Array<any> {
      return this.$store.getters['cartStore/getCartData']
    },
    getNotificationLoading(): boolean {
      return this.$store.getters['notificationStore/getNotificationDataLoading']
    },
    getNotificationData(): Array<any> {
      return this.$store.getters['notificationStore/getNotificationData']
    },
    getNotificationPagination(): Array<any> {
      return this.$store.getters['notificationStore/getNotificationPagination']
    },
    getUnReadNotification(): Array<any> {
      // console.log('getter',this.$store.getters['notificationStore/getUnReadNotificationData'])
      return this.$store.getters['notificationStore/getUnReadNotificationData']
    },
  },

  methods: {
    async getCart() {
      await this.$store.dispatch('cartStore/fetchCart')
    },
    async getProfile() {
      await this.$store.dispatch('profileStore/fetchProfileData')
    },
    async getNotification() {
      await this.$store.dispatch(
        'notificationStore/fetchUnreadNotificationData'
      )
      await this.$store.dispatch('notificationStore/fetchNotificationData', 5)
      // this.limitNotification()
    },
    limitNotification() {
      this.notifications = []
      this.getUnReadNotification.length
      if (this.getUnReadNotification.length > 0) {
        this.getUnReadNotification.forEach((el: any, i: number) => {
          if (i < 5) {
            this.notifications.push(el)
          }
        })
      }
      if (this.notifications.length < 5) {
        if (this.getNotificationData.length > 0) {
          let notifMissing = 5 - this.notifications.length
          this.getNotificationData.forEach((el: any) => {
            if (notifMissing > 0) {
              const isNotifExist = this.notifications.some(
                (e: any) => e.id == el.id
              )
              if (!isNotifExist) {
                this.notifications.push(el)
                notifMissing--
              }
            }
          })
        }
      }
    },
    readSingleNotification(data: any) {
      if (data.is_read == false) {
        NotificationModel.readSingle(data.id)
        this.getNotification()
      }
    },
    async logout() {
      await Auth.logout()
      window.location.assign('/')
    },
    close() {
      this.showProfile = false
    },
    getInitialName(name: string) {
      if (name) {
        let initials: any = name.split(' ')

        if (initials.length > 1) {
          initials = initials.shift().charAt(0) + initials.pop().charAt(0)
        } else {
          initials = name.substring(0, 1)
        }
        return initials.toUpperCase()
      } else {
        return '@'
      }
    },
    toHex(name: string) {
      if (name) {
        let hash = 0
        if (name?.length === 0) return hash
        for (let i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash)
          hash = hash & hash
        }
        let color = '#'
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 255
          color += ('00' + value.toString(16)).substr(-2)
        }
        return color
      } else {
        return '#EEEEEE'
      }
    },
    openLogout() {
      this.showProfile = false
      this.showModalLogout = true
    },
    closeLogout() {
      this.showModalLogout = false
    },
    openProfile() {
      this.showProfile = false
      this.$router.push('/profile')
    },
    dateNotif(date: string) {
      return moment(date)
        .locale('id')
        .format('D MMM')
    },

    timeSince(date: any) {
      const dateSince: any = new Date(date)
      const dateNow: any = new Date()
      const seconds = Math.floor((dateNow - dateSince) / 1000)

      let interval = seconds / 31536000

      if (interval > 1) {
        return Math.floor(interval) + ' tahun lalu'
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return Math.floor(interval) + ' bulan lalu'
      }
      interval = seconds / 86400
      if (interval > 1) {
        return Math.floor(interval) + ' hari lalu'
      }
      interval = seconds / 3600
      if (interval > 1) {
        return Math.floor(interval) + ' jam lalu'
      }
      interval = seconds / 60
      if (interval > 1) {
        return Math.floor(interval) + ' menit lalu'
      }
      return Math.floor(seconds) + ' detik lalu'
    },

    searching() {
      this.$router.push('/search?q=' + this.search)
    },

    redirectToCart() {
      this.$router.push('/cart/')
      this.openCart = false
    },
  },
})
</script>

<style lang="css">
.navbar-enter-from,
.navbar-leave-to {
  opacity: 0;
  transform: translateX(-50vw);
}
.navbar-enter-active,
.navbar-leave-active {
  transition: all 0.35s ease-out;
}
.navbar-enter-to,
.navbar-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.icon-enter-from,
.icon-leave-to {
  opacity: 0;
  transform: scale(0);
}

.icon-enter-active,
.icon-leave-active {
  transition: all 0.35s ease-out;
}
.icon-enter-to,
.icon-leave-from {
  opacity: 1;
  transform: scale(1);
}

.profile-enter-from,
.profile-leave-to {
  opacity: 0;
  transform: scale(1, 0);
}

.profile-enter-active,
.profile-leave-active {
  transition: all 0.35s ease-in-out;
}
.profile-enter-to,
.profile-leave-from {
  opacity: 1;
  transform: scale(1, 1);
}

.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-leave-active {
  transition: opacity 0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
