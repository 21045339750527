<template>
  <transition name="fade" appear>
    <div v-if="show" class="opacity-50 fixed inset-0 z-50 bg-black"></div>
  </transition>
  <transition
    enter-active-class="animated bounceIn"
    leave-active-class="animated zoomOut"
    appear
  >
    <div
      v-if="show"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
    >
      <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
        <!--content-->
        <div
          v-click-away="close"
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
        >
          <!--header-->
          <div class="flex items-start justify-end p-3 pb-0 rounded-t">
            <div
              class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
              @click="close()"
            >
              <svg
                class="fill-current text-[#383838]"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                ></path>
              </svg>
            </div>
          </div>
          <!--body-->
          <div
            class="pt-0 pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto"
          >
            <img
              src="/assets/images/modal/illustration-logout.svg"
              class="w-1/2 m-auto"
              alt=""
            />
            <p
              class="font-desain font-semibold my-3 text-center text-[#212121] text-xl"
            >
              Keluar
            </p>
            <p
              class="font-solusi font-medium text-sm text-center text-[#383838]"
            >
              Apakah Anda yakin ingin keluar dari SekolahDesain?
            </p>
            <div class="flex justify-evenly mt-8">
              <button
                @click="close()"
                class="w-36 py-2 font-solusi font-semibold rounded-full outline-none focus:outline-none border-dark-blue text-[#00446F]"
              >
                Tidak
              </button>
              <button
                @click="submit()"
                class="bg-[#00446F] w-36 py-2 text-white font-solusi font-semibold rounded-full outline-none focus:outline-none"
              >
                Ya, saya yakin
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal Logout',
  props: {
    show: Boolean,
    close: Function,
    submit: Function,
  },
}
</script>

<style></style>
