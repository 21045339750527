
import { defineComponent } from 'vue'
import Navbar from './Navbar.vue'
import MobileNavbar from './MobileNavbar.vue'
import Footer from './Footer.vue'
import Auth from '@/utils/Auth'

export default defineComponent({
  name: 'Layout',
  setup() {
    return {
      Auth,
    }
  },
  components: {
    Navbar,
    MobileNavbar,
    Footer,
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
})
