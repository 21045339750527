<template>
  <div class="max-w-6xl container md:m-auto px-4 py-2">
    <img
      src="/assets/images/mentor/logo-primary.svg"
      alt=""
      class="my-4 mr-auto"
    />
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div class="md:col-span-2">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-2">
          <div class="col-span-1 md:col-span-2">
            <div class="grid grid-cols-1 md:grid-cols-2">
              <div class="col-span-1">
                <p
                  class="mb-2 font-solusi text-[#212121] font-semibold text-lg"
                >
                  Produk
                </p>
                <p class="mb-2">
                  <a
                    class="font-solusi text-[#212121] text-base hover:text-blue-500"
                    href="https://www.sekolahdesain.id/tentang-kami"
                  >
                    Tentang Kami
                  </a>
                </p>
                <p class="mb-2">
                  <router-link
                    class="font-solusi text-[#212121] text-base hover:text-blue-500"
                    to="/why-sekdes"
                  >
                    Kenapa Kami
                  </router-link>
                </p>
              </div>
              <div class="col-span-1 md:col-span-1">
                <p
                  class="mb-2 font-solusi text-[#212121] font-semibold text-lg"
                >
                  Bantuan
                </p>
                <p class="mb-2">
                  <a
                    class="font-solusi text-[#212121] text-base hover:text-blue-500"
                    href="https://api.whatsapp.com/send/?phone=6285155421080&text=Halo%2C+Mimin+Owly.+Saya+butuh+bantuan+terkait+Sekolah+Desain.&app_absent=0"
                  >
                    Bantuan
                  </a>
                </p>
                <p class="mb-2">
                  <a
                    class="font-solusi text-[#212121] text-base hover:text-blue-500"
                    href="https://api.whatsapp.com/send/?phone=6285155421080"
                  >
                    Kontak Kami
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-span-1 md:col-span-2">
            <p class="mb-2 font-solusi text-[#212121] font-semibold text-lg">
              Lainnya
            </p>
            <div class="grid grid-cols-1 md:grid-cols-2">
              <div class="col-span-1">
                <p class="mb-2">
                  <router-link
                    class="font-solusi text-[#212121] text-base hover:text-blue-500"
                    to="/sk"
                  >
                    Syarat dan Ketentuan
                  </router-link>
                </p>
                <p class="mb-2">
                  <router-link
                    class="font-solusi text-[#212121] text-base hover:text-blue-500"
                    to="/kebijakan"
                  >
                    Kebijakan Privasi
                  </router-link>
                </p>
              </div>
              <div class="col-span-1">
                <p class="mb-2">
                  <a
                    class="font-solusi text-[#212121] text-base hover:text-blue-500"
                    href="https://promo.sekolahdesain.id/"
                  >
                    Promo SekolahDesain
                  </a>
                </p>
                <p class="mb-2">
                  <a
                    class="font-solusi text-[#212121] text-base hover:text-blue-500"
                    href="https://blog.sekolahdesain.id/"
                  >
                    Blog SekolahDesain
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="md:col-span-1">
        <div class="flex w-full">
          <a
            href="https://play.google.com/store/apps/details?id=id.sekolahdesain.app"
            class=""
          >
            <img
              src="/assets/images/footer/play-store.png"
              class="w-9/12 m-auto"
              alt=""
            />
          </a>
          <a href="https://apps.apple.com/us/app/sekolah-desain/id1592918511">
            <img
              src="/assets/images/footer/app-store.png"
              class="w-9/12 m-auto"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
    <div class="block md:flex justify-center md:justify-between">
      <div class="flex items-center justify-center md:justify-items-start my-4">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="map-marker-alt"
          class="svg-inline--fa fa-map-marker-alt fa-w-12 w-3 flex-shrink-0"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            fill="#00446F"
            d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
          ></path>
        </svg>
        <span class="font-solusi text-base text-[#212121] ml-2"
          >Office Park B-19, The Park Solobaru, Madegondo, Grogol, Sukoharjo
          57552</span
        >
      </div>
      <div class="flex items-center justify-center md:justify-items-end my-4">
        <img
          src="https://assets.sekolahdesain.id/setting/wa.svg"
          alt="Whatsapp Image"
          class="w-6 h-6"
        />
        <span class="font-solusi text-base text-[#212121] ml-2"
          >+62 851-5542-1080 (08:00 - 16.00 WIB)</span
        >
        <a
          href="https://www.linkedin.com/company/sekolahdesainid/"
          class="ml-2"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            class="w-6 h-6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#00446F" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.91769 11.5753C11.1134 11.5753 11.8581 10.7802 11.8581 9.78753C11.8357 8.77262 11.1134 7.99992 9.94038 7.99992C8.76699 7.99992 8 8.77262 8 9.78753C8 10.7802 8.74429 11.5753 9.89534 11.5753H9.91769ZM11.6323 23.3333V12.9873H8.20281V23.3333H11.6323Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.5303 23.3333H16.9601V17.5558C16.9601 17.2465 16.9825 16.9375 17.0731 16.7167C17.321 16.0988 17.8854 15.4588 18.8328 15.4588C20.0743 15.4588 20.5705 16.408 20.5705 17.7985V23.3333H23.9999V17.4011C23.9999 14.2234 22.308 12.7446 20.0513 12.7446C18.201 12.7446 17.3887 13.7819 16.9375 14.488H16.9601V12.9874H13.5303C13.5753 13.9581 13.5303 23.3333 13.5303 23.3333Z"
              fill="white"
            />
          </svg>
        </a>
        <a href="https://www.instagram.com/sekolahdesain_id/" class="ml-2">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            class="w-6 h-6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#00446F" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.7384 8H19.2618C21.8682 8 24 10.1324 24 12.7384V19.2618C24 21.8682 21.8682 24 19.2618 24H12.7384C10.1324 24 8 21.8682 8 19.2618V12.7384C8 10.1324 10.1324 8 12.7384 8ZM20.7893 19.4432C20.251 19.4432 19.8152 19.8788 19.8152 20.417C19.8152 20.9553 20.251 21.3911 20.7893 21.3911C21.3276 21.3911 21.7631 20.9553 21.7631 20.417C21.7631 19.879 21.3273 19.4432 20.7893 19.4432ZM15.9999 12.0111C13.7971 12.0111 12.0113 13.7971 12.0113 15.9999C12.0113 18.2027 13.7968 19.9887 15.9999 19.9887C18.215 19.9887 19.9884 18.1894 19.9884 15.9999C19.9884 13.7971 18.2027 12.0111 15.9999 12.0111ZM18.5209 15.9999C18.5209 17.3921 17.3921 18.5209 15.9999 18.5209C14.6077 18.5209 13.4789 17.3918 13.4789 15.9999C13.4789 14.6079 14.6077 13.4789 15.9999 13.4789C17.3921 13.4789 18.5209 14.6077 18.5209 15.9999Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="flex items-center">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        class="w-3 h-3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.94025 1.5C8.39233 1.5 10.3805 3.48807 10.3805 5.94025C10.3805 8.39242 8.39242 10.3805 5.94025 10.3805C3.48807 10.3805 1.5 8.39269 1.5 5.94025C1.5 3.48816 3.48807 1.5 5.94025 1.5ZM7.95206 7.57086C7.45831 8.17965 6.72457 8.53115 5.94025 8.53115C4.50925 8.53115 3.34899 7.37089 3.34899 5.93988C3.34899 4.50888 4.50925 3.34862 5.94025 3.34862C6.72458 3.34862 7.45796 3.70044 7.95206 4.30891L7.26505 4.86743C6.94084 4.46747 6.45487 4.23671 5.94025 4.23671C4.99944 4.23671 4.23705 4.99911 4.23705 5.9399C4.23705 6.8807 4.99945 7.6431 5.94025 7.6431C6.45488 7.6431 6.94085 7.41232 7.26505 7.01237L7.95206 7.57086ZM5.94025 2.38807C3.97836 2.38807 2.38843 3.97836 2.38843 5.93988C2.38843 7.90177 3.97872 9.4917 5.94025 9.4917C7.90213 9.4917 9.49206 7.90141 9.49206 5.93988C9.49206 3.978 7.90177 2.38807 5.94025 2.38807Z"
          fill="#333333"
        />
      </svg>
      <span class="font-solusi text-2xs text-[#383838]"
        >2021 SekolahDesain.id. All Rights Reserved</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Footer',
})
</script>

<style scoped>
.text-2xs {
  font-size: 10px;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .md\:mr-auto {
    margin-right: auto !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
}
</style>
