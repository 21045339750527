
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'
import ModalLogout from '@/main/components/Modals/ModalLogout.vue'
import moment from 'moment'
import NotificationModel from '@/models/NotificationModel'

export default defineComponent({
  name: 'Navbar Member',

  components: {
    ModalLogout,
  },

  setup() {
    return {
      Auth,
    }
  },

  data() {
    return {
      showProfile: false,
      openSearch: false as boolean,
      openCart: false,
      openNotif: false,
      routes: [
        {
          name: 'Beranda',
          path: '/',
        },
        {
          name: 'Kategori',
          path: '/category',
        },
        {
          name: 'Kelas Saya',
          path: '/my-classes',
        },
      ],
      isMobile: false,
      showModalLogout: false,
      search: '' as any,
      notifications: [] as any,
      showBannerDownload: true,
    }
  },

  created() {
    if (window.innerWidth < 768) {
      this.isMobile = true
    }
    if (this.currentRouteName == 'Search') {
      const query: any = this.$route.query.q
      this.search = decodeURI(query)
    }
  },

  async mounted() {
    // console.log(this.$router.currentRoute.value)
    if (Auth.isLoggedIn()) {
      if (Object.keys(this.getProfileData).length == 0) {
        this.getProfile()
      }
      if (Object.keys(this.getCartData).length == 0) {
        this.getCart()
      }
      await this.getNotification()
    }
  },

  computed: {
    currentRouteValue() {
      return this.$router.currentRoute.value
    },
    currentRouteName() {
      return this.$route.name
    },
    getProfileData(): any {
      return this.$store.getters['profileStore/getProfileData']
    },
    getCartLoading(): boolean {
      return this.$store.getters['cartStore/getCartLoading']
    },
    getCartData(): Array<any> {
      return this.$store.getters['cartStore/getCartData']
    },
    getNotificationLoading(): boolean {
      return this.$store.getters['notificationStore/getNotificationDataLoading']
    },
    getNotificationData(): Array<any> {
      return this.$store.getters['notificationStore/getNotificationData']
    },
    getNotificationPagination(): Array<any> {
      return this.$store.getters['notificationStore/getNotificationPagination']
    },
    getUnReadNotification(): Array<any> {
      // console.log('getter',this.$store.getters['notificationStore/getUnReadNotificationData'])
      return this.$store.getters['notificationStore/getUnReadNotificationData']
    },
  },

  methods: {
    async getCart() {
      await this.$store.dispatch('cartStore/fetchCart')
    },
    async getProfile() {
      await this.$store.dispatch('profileStore/fetchProfileData')
    },
    async getNotification() {
      await this.$store.dispatch(
        'notificationStore/fetchUnreadNotificationData'
      )
      await this.$store.dispatch('notificationStore/fetchNotificationData', 5)
      // this.limitNotification()
    },
    limitNotification() {
      this.notifications = []
      this.getUnReadNotification.length
      if (this.getUnReadNotification.length > 0) {
        this.getUnReadNotification.forEach((el: any, i: number) => {
          if (i < 5) {
            this.notifications.push(el)
          }
        })
      }
      if (this.notifications.length < 5) {
        if (this.getNotificationData.length > 0) {
          let notifMissing = 5 - this.notifications.length
          this.getNotificationData.forEach((el: any) => {
            if (notifMissing > 0) {
              const isNotifExist = this.notifications.some(
                (e: any) => e.id == el.id
              )
              if (!isNotifExist) {
                this.notifications.push(el)
                notifMissing--
              }
            }
          })
        }
      }
    },
    readSingleNotification(data: any) {
      if (data.is_read == false) {
        NotificationModel.readSingle(data.id)
        this.getNotification()
      }
    },
    async logout() {
      await Auth.logout()
      window.location.assign('/')
    },
    close() {
      this.showProfile = false
    },
    getInitialName(name: string) {
      if (name) {
        let initials: any = name.split(' ')

        if (initials.length > 1) {
          initials = initials.shift().charAt(0) + initials.pop().charAt(0)
        } else {
          initials = name.substring(0, 1)
        }
        return initials.toUpperCase()
      } else {
        return '@'
      }
    },
    toHex(name: string) {
      if (name) {
        let hash = 0
        if (name?.length === 0) return hash
        for (let i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash)
          hash = hash & hash
        }
        let color = '#'
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 255
          color += ('00' + value.toString(16)).substr(-2)
        }
        return color
      } else {
        return '#EEEEEE'
      }
    },
    openLogout() {
      this.showProfile = false
      this.showModalLogout = true
    },
    closeLogout() {
      this.showModalLogout = false
    },
    openProfile() {
      this.showProfile = false
      this.$router.push('/profile')
    },
    dateNotif(date: string) {
      return moment(date)
        .locale('id')
        .format('D MMM')
    },

    timeSince(date: any) {
      const dateSince: any = new Date(date)
      const dateNow: any = new Date()
      const seconds = Math.floor((dateNow - dateSince) / 1000)

      let interval = seconds / 31536000

      if (interval > 1) {
        return Math.floor(interval) + ' tahun lalu'
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return Math.floor(interval) + ' bulan lalu'
      }
      interval = seconds / 86400
      if (interval > 1) {
        return Math.floor(interval) + ' hari lalu'
      }
      interval = seconds / 3600
      if (interval > 1) {
        return Math.floor(interval) + ' jam lalu'
      }
      interval = seconds / 60
      if (interval > 1) {
        return Math.floor(interval) + ' menit lalu'
      }
      return Math.floor(seconds) + ' detik lalu'
    },

    searching() {
      this.$router.push('/search?q=' + this.search)
    },

    redirectToCart() {
      this.$router.push('/cart/')
      this.openCart = false
    },
  },
})
